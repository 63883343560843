<template>
    <div>
        <h1 class="title is-4">
            {{ $t("Interface.Payment.Yandex.Pay.Title") }}
        </h1>
        <div id="payment-form" />
        <b-loading :is-full-page="true" :active.sync="IsLoading" :can-cancel="false" />
    </div>
</template>

<script>
import LoadingState from "@/mixins/LoadingState"
import Helpers from "@/utils/Helpers"

export default {
    name: "PaymentYandexPay",
    mixins: [LoadingState],
    created() {
        this.loadYandexCheckoutScript()
    },
    mounted() {
        this.switchLoading()
        const script = document.querySelector("#yandex_checkout")
        const THIS = this
        script.addEventListener("load", function () {
            THIS.initYandexCheckout()
        })
    },
    methods: {
        loadYandexCheckoutScript() {
            const script = document.createElement("script")
            script.src = "https://kassa.yandex.ru/checkout-ui/v2.js"
            script.type = "text/javascript"
            script.async = false
            script.id = "yandex_checkout"
            document.getElementsByTagName("head")[0].appendChild(script)
        },
        initYandexCheckout() {
            if (this.$store.getters.IS_YANDEX_PAYMENT_CREATED) {
                const RouteData = `${window.location.protocol}//${window.location.host}${this.$router.resolve({ name: "Payment-Yandex-Success" }).href}`
                const checkout = new window.YandexCheckout({
                    // Токен, который перед проведением оплаты нужно получить от Яндекс.Кассы
                    confirmation_token: this.$store.state.Payment.ConfirmationToken,
                    // Ссылка на страницу завершения оплаты
                    return_url: RouteData,
                    error_callback(error) {
                        // Обработка ошибок инициализации
                        Helpers.notify("is-danger", error)
                    }
                })
                checkout.render("payment-form")
                this.switchLoading()
            }
            else {
                Helpers.notify("is-danger", this.$t("Message.Payment.NotReady"))
                this.$router.push({ name: "Contract" })
            }
        }
    },
    metaInfo() {
        return {
            title: this.$t("Interface.Payment.Yandex.Pay.Title")
        }
    }
}
</script>
